import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  Fragment,
} from 'react';
import { Helmet } from 'react-helmet';

import useLottery, { useLotteryHistoryPagination } from 'hooks/useLottery';
import LotteryResultCard from 'components/LotteryResultCard';
import LotteryHistoryHeader from './LotteryHistoryHeader/LotteryHistoryHeader';
import LotteryHistorySearch, {
  SearchValue,
} from './LotteryHistorySearch/LotteryHistorySearch';
import classes from './LotteryHistoryPage.module.scss';
import LotteryListPagination from 'components/LotteryListPagination';
import useErrorState from 'hooks/useErrorState';
import classnames from 'classnames';

const HistoryErrorMessage = (props: { message?: string }) => {
  const { message } = props || {};
  return (
    <div className={classnames(classes.pageTitle, classes.errorMessage)}>
      {message}
    </div>
  );
};

export default function LotteryHistoryPage() {
  const [searchText, setSearchText] = useState<string>();
  const [searchTimestamp, setSearchTimestamp] = useState<number>();

  const {
    queryAndSetLotteryList,
    queryAndSetLotteryHistoryList,
    lotteryHistoryListPaged,
  } = useLottery();

  const { lotteryHistory: lotteryHistoryError } = useErrorState();

  const { currentPageNumber } = useLotteryHistoryPagination();

  const queryNowLotteryHistoryList = useCallback(() => {
    queryAndSetLotteryHistoryList('date', { date: Date.now() });
  }, [queryAndSetLotteryHistoryList]);

  useEffect(() => {
    queryAndSetLotteryList();

    // How to search by draw id
    // queryAndSetLotteryHistoryList('id', { id: '20200508012' });

    // How to search by milliseconds
    queryNowLotteryHistoryList();
  }, [
    queryAndSetLotteryHistoryList,
    queryAndSetLotteryList,
    queryNowLotteryHistoryList,
  ]);

  const currentLotteryList = useMemo(
    () => lotteryHistoryListPaged[currentPageNumber - 1] || [],
    [currentPageNumber, lotteryHistoryListPaged]
  );

  const handleSearchChange = useCallback(
    ({ searchText, searchTimestamp }: SearchValue) => {
      setSearchText(searchText);
      setSearchTimestamp(searchTimestamp);
    },
    []
  );

  const handleSearch = useCallback(() => {
    if (searchText) {
      queryAndSetLotteryHistoryList('id', { id: searchText });
      setSearchTimestamp(undefined);
      return;
    }

    if (searchTimestamp) {
      queryAndSetLotteryHistoryList('date', {
        date: searchTimestamp,
      });
      return;
    }

    queryNowLotteryHistoryList();
  }, [
    searchTimestamp,
    searchText,
    queryNowLotteryHistoryList,
    queryAndSetLotteryHistoryList,
  ]);

  return (
    <Fragment>
      <Helmet>
        <title>Canada Lottery - History</title>
      </Helmet>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.pageHeader}>
            <LotteryHistoryHeader />
          </div>
          <div className={classes.pageSearch}>
            <LotteryHistorySearch
              value={{ searchText, searchTimestamp }}
              onChange={handleSearchChange}
              onSearch={handleSearch}
            />
          </div>
          {lotteryHistoryError ? (
            <HistoryErrorMessage
              message={lotteryHistoryError.message}
            ></HistoryErrorMessage>
          ) : (
            <h3 className={classes.pageTitle}>History Records</h3>
          )}

          <div className={classes.results}>
            {currentLotteryList.map((lot) => (
              <LotteryResultCard
                key={lot.uniqueIssueNumber}
                lottery={lot}
              ></LotteryResultCard>
            ))}
          </div>
          <LotteryListPagination />
        </div>
      </div>
    </Fragment>
  );
}
