import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export default function useSearchParams() {
  const location = useLocation();

  const params = useMemo<{ [x: string]: string }>(
    () =>
      location.search
        .slice(1)
        .split('&')
        .reduce((pre, cur) => {
          const [key, value] = cur.split('=');
          return {
            ...pre,
            [key]: value,
          };
        }, {}),
    [location.search]
  );

  return params;
}
