export enum RoutePath {
  HOME = '/',
  RULE = '/rule',
  LOTTERY_HISTORY = '/lottery-history',
}

export enum RouteKey {
  HOME,
  RULE,
  LOTTERY_HISTORY,
}

export const RouteName = {
  HOME: 'HOME',
  RULE: 'RULES',
  LOTTERY_HISTORY: 'LOTTERY HISTORY',
};
