import { Lottery, LotteryHistory } from 'types/apis/lottery.type';
import { LotteryActionType } from '../actionTypes/lotteryActionTypes';
import ActionType from 'store/actionTypes/actionTypeEnum';
import { LotteryPaginationProps } from 'store/reducers/stateTypes';

// TypeScript infers that this function is returning SendMessageAction
export function setLotteryList(lotteryList: Array<Lottery>): LotteryActionType {
  return {
    type: ActionType.SET_LOTTERY_LIST,
    payload: lotteryList,
  };
}

export function setLatestLotteryList(
  latestLottery: Lottery
): LotteryActionType {
  return {
    type: ActionType.SET_LATEST_LOTTERY,
    payload: latestLottery,
  };
}

export function setLotteryHistoryList(
  lotteryList: Array<LotteryHistory>
): LotteryActionType {
  return {
    type: ActionType.SET_LOTTERY_HISTORY_LIST,
    payload: lotteryList,
  };
}
export function setLotteryPagination(
  ...option: { key: keyof LotteryPaginationProps; value: number }[]
): LotteryActionType {
  const payload = option.reduce(
    (pre, cur) => ({ ...pre, [cur.key]: cur.value }),
    {}
  ) as LotteryPaginationProps;
  return {
    type: ActionType.SET_LOTTERY_PAGINATION,
    payload,
  };
}
