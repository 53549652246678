import React, { useCallback, useMemo } from 'react';

import { Dayjs } from 'dayjs';

import { useKeyPressEnter } from 'hooks/useKeyboardEvent';

import AntdInput from 'components/AntdInput/AntdInput';
import GeneralButton from 'components/GeneralButton/index';
import DatePicker from 'components/DatePicker/DatePicker';

import styles from './LotteryHistorySearch.module.scss';

export interface SearchValue {
  searchText?: string;
  searchTimestamp?: number;
}

interface Prop {
  value?: SearchValue;
  onChange?: (value: SearchValue) => any;
  onSearch?: () => any;
}

export default function LotteryHistorySearch({
  value,
  onChange,
  onSearch,
}: Prop) {
  const { handleKeyPressEnter } = useKeyPressEnter();

  const callOnChange = useCallback(
    (changeValue: SearchValue) => {
      if (onChange) {
        onChange(changeValue);
      }
    },
    [onChange]
  );
  const callOnSearch = useCallback(() => {
    if (onSearch) {
      onSearch();
    }
  }, [onSearch]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      callOnChange({
        searchText: event.target.value,
        searchTimestamp: value && value.searchTimestamp,
      });
    },
    [callOnChange, value]
  );

  const disabledDate = useCallback(
    (date: Dayjs) => date.valueOf() > Date.now(),
    []
  );
  const handleDatePickerChange = useCallback(
    (changeValue: number) => {
      callOnChange({
        searchText: value && value.searchText,
        searchTimestamp: changeValue,
      });
    },
    [callOnChange, value]
  );

  const handleSearchEnter = useMemo(() => handleKeyPressEnter(callOnSearch), [
    callOnSearch,
    handleKeyPressEnter,
  ]);
  const handleSearchClick = useCallback(callOnSearch, [callOnSearch]);

  return (
    <section
      className={styles['lottery-history-search']}
      onKeyPress={handleSearchEnter}
    >
      <h4 className={styles['lottery-history-search-title']}>
        Search by Month &amp; Year
      </h4>
      <div className={styles['lottery-history-search-content']}>
        <div className={styles['lottery-history-draw']}>
          <div className={styles['lottery-history-draw-hint']}>Draw</div>
          <AntdInput
            type="number"
            placeholder="Draw"
            value={value && value.searchText}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles['lottery-history-date']}>
          <div className={styles['lottery-history-date-hint']}>Date</div>
          <DatePicker
            disabledDate={disabledDate}
            value={value && value.searchTimestamp}
            onChange={handleDatePickerChange}
          />
        </div>
        <GeneralButton
          className={styles['lottery-history-search-button']}
          contentClassName={styles['lottery-history-search-button-content']}
          onClick={handleSearchClick}
        >
          SEARCH
        </GeneralButton>
      </div>
    </section>
  );
}
