import { RouteProps } from 'react-router-dom';

import { RoutePath, RouteKey, RouteName } from 'constants/route-path';

import { generatorPageComponent } from 'libs/generatorPage';

import HomePage from 'pages/HomePage';
import RulesPage from 'pages/RulesPage';
import LotteryHistoryPage from 'pages/LotteryHistoryPage';

export interface RouteUnit extends RouteProps {
  name: string;
  key: RouteKey;
  path: RoutePath;
  defaultState?: {
    [x: string]: string;
  };
  defaultSearch?: string;

  routes?: Array<RouteUnit>;
}

const routes: {
  [scope: string]: Array<RouteUnit>;
} = {
  App: [
    {
      name: RouteName.HOME,
      key: RouteKey.HOME,
      path: RoutePath.HOME,
      exact: true,
      component: generatorPageComponent(HomePage, { showHeaderDetail: true }),
    },
    {
      name: RouteName.RULE,
      key: RouteKey.RULE,
      path: RoutePath.RULE,
      component: generatorPageComponent(RulesPage),
    },
    {
      name: RouteName.LOTTERY_HISTORY,
      key: RouteKey.LOTTERY_HISTORY,
      path: RoutePath.LOTTERY_HISTORY,
      defaultState: {
        page: '1',
      },
      defaultSearch: '?page=1',
      component: generatorPageComponent(LotteryHistoryPage),
    },
  ],
};

export default routes;
