enum ActionType {
  SET_LOTTERY_LIST = 'set lottery data list',
  SET_LATEST_LOTTERY = 'SET_LATEST_LOTTERY',
  SET_LOTTERY_HISTORY_LIST = 'set lottery history data list',
  SET_LOTTERY_PAGINATION = 'set properties of pagination',
  SET_ERROR_STATE = 'set error state by key',

  REMOVE_ERROR_STATE = 'remove error state by key',
  CLEAR_ERROR_STATE = 'remove all error state',
}

export default ActionType;
