import Axios from 'axios';
import * as luxon from 'luxon';
import { Lottery, LotteryHistory } from 'types/apis/lottery.type';

enum Endpoints {
  CURRENT_TIME = 'result/service/mobile/results/currentTime',
  LOTTERY_LIST = 'result/service/mobile/results/hist',
  LOTTERY_LIST_BY_ID = 'result/service/mobile/results/findOpenCodeByIssueNum',
  LOTTERY_LIST_BY_DATE = 'result/service/mobile/results/histByDate',
}

type AxiosQuery<P, T> = (option?: P) => Promise<{ data: T }>;

const baseURL: string = {
  development: '/api/v1',
  production: '/api/v1',
  test: '/api/v1',
}[process.env.NODE_ENV];

const service = {
  v1: Axios.create({
    baseURL,
  }),
};

interface DefApi {
  getCurrentTime: AxiosQuery<undefined, number>;
  getLotteryList: AxiosQuery<
    { limit?: number; gameUniqueId?: string },
    Lottery[]
  >;
  getLotteryListById: AxiosQuery<
    { uniqueIssueNumber: string; gameUniqueId?: string },
    string
  >;
  getLotteryListByDate: AxiosQuery<
    { limit?: number; dateMillis?: number; gameUniqueId?: string },
    LotteryHistory[]
  >;
}

const api: DefApi = {
  getCurrentTime() {
    return service.v1.get(`${Endpoints.CURRENT_TIME}`);
  },
  getLotteryList(option) {
    const { limit = 10, gameUniqueId = 'HF_XYFT' } = option || {};
    return service.v1.get(
      `${Endpoints.LOTTERY_LIST}/${gameUniqueId}?limit=${limit}`
    );
  },
  getLotteryListById(option) {
    const { uniqueIssueNumber, gameUniqueId = 'HF_XYFT' } = option || {};
    return service.v1.get(
      `${Endpoints.LOTTERY_LIST_BY_ID}/${gameUniqueId}/${uniqueIssueNumber}?brand=999999999`
    );
  },
  getLotteryListByDate(option) {
    const { limit = 10, dateMillis = Date.now(), gameUniqueId = 'HF_XYFT' } =
      option || {};
    const dateString = luxon.DateTime.fromMillis(dateMillis).toFormat(
      'yyyy-LL-dd'
    );
    return service.v1.get(
      `${Endpoints.LOTTERY_LIST_BY_DATE}?gameUniqueId=${gameUniqueId}&brand=999999999&date=${dateString}&limit=${limit}`
    );
  },
};

export default api;
