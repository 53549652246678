import { combineReducers } from 'redux';
import { lotteryReducer } from './lotteryReducer';
import { errorReducer } from './errorReducer';

const rootReducer = combineReducers({
  lottery: lotteryReducer,
  error: errorReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
