import React from 'react';
import classes from './LotteryResultCard.module.scss';
import { Lottery, LotteryHistory } from 'types/apis/lottery.type';
import LotteryNumber from 'components/LotteryNumber';

type LotteryResultCardProps = {
  lottery?: Lottery | LotteryHistory;
};

export default function LotteryResultCard(props: LotteryResultCardProps) {
  const { lottery } = props;
  return (
    <section className={classes.container}>
      <div className={classes.mask}></div>
      <section className={classes.blockDraw}>
        <div className={classes.title}>Draw</div>
        <strong className={classes.content}>
          {lottery?.uniqueIssueNumber}
        </strong>
      </section>
      <section className={classes.blockNumbers}>
        <div className={classes.title}>Numbers</div>
        <div className={classes.content}>
          {lottery?.openCode.split(',').map((code, index) => (
            <LotteryNumber
              key={`${code}-${index}`}
              value={code}
            ></LotteryNumber>
          ))}
        </div>
      </section>
    </section>
  );
}
