import React, { useRef } from 'react';

import styles from './DefaultFooter.module.scss';

import useLogoImage from 'hooks/useLogoImage';
import { RouteKey } from 'constants/route-path';
import { getRoutesByRouteKeys } from 'libs/route';

import NavMenu from 'layouts/NavMenu/Index';

const navMenuKeys = [RouteKey.RULE, RouteKey.HOME, RouteKey.LOTTERY_HISTORY];

export default function DefaultFooter() {
  const { LogoImage } = useLogoImage();
  const { current: navMenu } = useRef(getRoutesByRouteKeys(navMenuKeys));

  return (
    <header className={styles['default-footer-style']}>
      <div className={styles['default-footer-container']}>
        <LogoImage className={styles['default-footer-logo']} />
        <NavMenu
          sameTheme
          className={styles['default-footer-nav-menu']}
          navMenu={navMenu}
        />
        <p className={styles['default-footer-copyright']}>
          Copyright© 2020 Canada Lottery. All Rights Reserved.
        </p>
      </div>
    </header>
  );
}
