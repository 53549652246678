import React, { useCallback, useMemo, useState } from 'react';

import { Popover, Button, Calendar } from 'antd';
import { CalendarProps } from 'antd/lib/calendar/generateCalendar';
import dayjs, { Dayjs } from 'dayjs';

import styles from './DatePicker.module.scss';

type CalendarDayjsProp = CalendarProps<Dayjs>;
interface Prop {
  disabledDate?: CalendarDayjsProp['disabledDate'];
  value?: number;
  onChange?: (value: number) => any;
}

export default React.memo(function DatePicker({
  disabledDate,
  value,
  onChange,
}: Prop) {
  const [isVisible, setIsVisible] = useState(false);

  const calendarValue = useMemo(
    () => (value ? (dayjs(value) as any) : dayjs()),
    [value]
  );
  const selectedDateText = useMemo(() => {
    if (value) {
      return dayjs(value).format('YYYY-MM-DD');
    }

    return '- Select a Date -';
  }, [value]);

  const callOnChange = useCallback(
    (value: number) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  const handleVisible = useCallback((visible: boolean) => {
    setIsVisible(visible);
  }, []);

  const handleChange = useCallback(
    (value) => {
      // value 經過 AntdDayjsWebpackPlugin 轉變後，已是 Dayjs 而不是原先官方的 Moment;
      const dayjsValue: Dayjs = value;
      callOnChange(dayjsValue.valueOf());
    },
    [callOnChange]
  );

  const calendarRender = useMemo(
    () => (
      <div className={styles['calendar-container']}>
        <Calendar
          fullscreen={false}
          disabledDate={disabledDate as any}
          value={calendarValue}
          onChange={handleChange}
        />
      </div>
    ),
    [calendarValue, disabledDate, handleChange]
  );

  return (
    <Popover
      placement="bottom"
      trigger="click"
      content={calendarRender}
      onVisibleChange={handleVisible}
    >
      <Button
        className={styles['date-picker']}
        data-popover-visible={isVisible}
        data-selected-date={value != null}
      >
        {selectedDateText}
        <span className={styles['date-picker-arrow']} />
      </Button>
    </Popover>
  );
});
