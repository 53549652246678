import React, {
  useEffect,
  Fragment,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import LotteryResultCard from 'components/LotteryResultCard';
import classes from './HomePage.module.scss';

import useLottery from 'hooks/useLottery';
import { RouteKey } from 'constants/route-path';
import { getRouteByRouteKey } from 'libs/route';

import GeneralButton from 'components/GeneralButton'; // GeneralButtonTypeEnum
import { Helmet } from 'react-helmet';

export default function HomePage() {
  const { current: lotteryHistoryLink } = useRef(
    getRouteByRouteKey(RouteKey.LOTTERY_HISTORY)
  );
  const { lotteryList, queryAndSetLotteryList } = useLottery();

  useEffect(() => {
    queryAndSetLotteryList();
  }, [queryAndSetLotteryList]);

  const SearchMore = useCallback(() => {
    if (!lotteryHistoryLink) {
      return <GeneralButton>SEARCH MORE</GeneralButton>;
    }

    return (
      <GeneralButton
        elType="link"
        to={{
          pathname: lotteryHistoryLink.path,
          state: lotteryHistoryLink.defaultState,
          search: lotteryHistoryLink.defaultSearch || '',
        }}
      >
        SEARCH MORE
      </GeneralButton>
    );
  }, [lotteryHistoryLink]);

  const LotteryResult = useMemo(() => {
    try {
      return lotteryList.map((lot) => (
        <LotteryResultCard
          key={lot.uniqueIssueNumber}
          lottery={lot}
        ></LotteryResultCard>
      ));
    } catch (error) {
      return null;
    }
  }, [lotteryList]);

  return (
    <Fragment>
      <Helmet>
        <title>Canada Lottery - Home</title>
      </Helmet>
      <div className={classes.root}>
        <div className={classes.container}>
          <h3 className={classes.pageTitle}>Latest Result</h3>
          <div className={classes.results}>{LotteryResult}</div>
          <div className={classes.action}>
            <SearchMore />
            {/* <strong className={classes.HowCanWe}>
            How can we help you? Let us provide solution for you!
          </strong>
          <GeneralButton
            elType='a'
            styleType={GeneralButtonTypeEnum.OUTLINED}
          >{`ASK NOW`}</GeneralButton> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
