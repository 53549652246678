import React from 'react';

import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';

import styles from './AntdInput.module.scss';

export default React.memo(function AntdInput(props: InputProps) {
  return (
    <Input
      {...props}
      className={`${styles['antd-input']} ${props.className}`}
    />
  );
});
