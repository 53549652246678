import React, { Fragment } from 'react';
import classes from './RulesPage.module.scss';
import lotteryRules from 'assets/others/lotteryRules.json';
import RuleParagraph from 'components/RuleParagraph';
import { Helmet } from 'react-helmet';

export default function RulesPage() {
  return (
    <Fragment>
      <Helmet>
        <title>Canada Lottery - Rules</title>
      </Helmet>
      <article className={classes.root}>
        <div className={classes.container}>
          <div className={classes.pageTitle}>Rules</div>
          <div className={classes.rulesContent}>
            {lotteryRules.rules.map((lr) => (
              <RuleParagraph
                key={lr.title}
                title={lr.title}
                content={lr.content}
                contentType={lr.contentType}
                contentStyle={lr.contentStyle}
              ></RuleParagraph>
            ))}
          </div>
        </div>
      </article>
    </Fragment>
  );
}
