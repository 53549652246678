import { ErrorState } from './stateTypes';
import { ErrorActionType } from 'store/actionTypes/errorActionTypes';
import ActionType from 'store/actionTypes/actionTypeEnum';

const initialState: ErrorState = {
  lotteryHistory: null,
  lottery: null,
};

export function errorReducer(
  state = initialState,
  action: ErrorActionType
): ErrorState {
  switch (action.type) {
    case ActionType.SET_ERROR_STATE: {
      return {
        ...state,
        [action.payload.key]: action.payload.error,
      };
    }
    case ActionType.REMOVE_ERROR_STATE: {
      return {
        ...state,
        [action.payload.key]: null,
      };
    }
    case ActionType.CLEAR_ERROR_STATE: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}
