import React, { useMemo, Fragment } from 'react';
import classes from './LotteryListPaginationNumber.module.scss';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useLotteryHistoryPagination } from 'hooks/useLottery';

type LotteryListPaginationNumberProps = {
  pageNumber: string | number;
  hidden?: boolean;
  isDot?: boolean;
};

export default function LotteryListPaginationNumber(
  props: LotteryListPaginationNumberProps
) {
  const { pageNumber, hidden = false, isDot = false } = props || {};
  const { currentPageNumber } = useLotteryHistoryPagination();

  const isChoosen = useMemo(() => currentPageNumber === pageNumber, [
    currentPageNumber,
    pageNumber,
  ]);

  const classCollection = useMemo(() => {
    const classNameCollection = [classes.container];

    if (isChoosen) {
      classNameCollection.push(classes.choosen);
    }
    if (hidden) {
      classNameCollection.push(classes.hidden);
    }
    return classnames(...classNameCollection);
  }, [hidden, isChoosen]);

  return (
    <Fragment>
      {isDot ? (
        <div className={classnames(classes.dot)}>...</div>
      ) : (
        <Link
          to={{
            pathname: `/lottery-history`,
            search: `?page=${pageNumber}`,
            state: { page: pageNumber },
          }}
          className={classCollection}
        >
          <div className={classes.mask}></div>
          <div className={classes.content}>{pageNumber}</div>
        </Link>
      )}
    </Fragment>
  );
}
