import React, { useCallback } from 'react';
import { Link, NavLink, LinkProps, NavLinkProps } from 'react-router-dom';

import classes from './GeneralButton.module.scss';
import classnames from 'classnames';

export enum GeneralButtonTypeEnum {
  FILLED,
  OUTLINED,
}

type ElType = 'button' | 'a' | 'link' | 'nav-link';

interface CommonProp {
  styleType?: GeneralButtonTypeEnum;
  contentClassName?: string;
  elType?: ElType;
}

interface ButtonProp
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    CommonProp {
  elType?: 'button';
}

interface AnchorProp
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    CommonProp {
  elType?: 'a';
}

interface RouterLinkProp
  extends LinkProps,
    React.RefAttributes<HTMLAnchorElement>,
    CommonProp {
  elType?: 'link';
}

interface RouterNavLinkProp
  extends NavLinkProps,
    React.RefAttributes<HTMLAnchorElement>,
    CommonProp {
  elType?: 'nav-link';
}

type Prop = ButtonProp | AnchorProp | RouterLinkProp | RouterNavLinkProp;

const GeneralButton = (props: Prop) => {
  const {
    children,
    styleType = GeneralButtonTypeEnum.FILLED,
    elType = 'button',
    className,
    contentClassName,
    ...rest
  } = props || {};

  const RootEl = useCallback(
    ({ children, ...p }: Prop) => {
      switch (elType) {
        case 'a': {
          return <a {...(p as AnchorProp)}>{children}</a>;
        }
        case 'button': {
          return <button {...(p as ButtonProp)}>{children}</button>;
        }
        case 'link': {
          return <Link {...(p as RouterLinkProp)}>{children}</Link>;
        }
        case 'nav-link': {
          return <NavLink {...(p as RouterNavLinkProp)}>{children}</NavLink>;
        }
        default: {
          return <button {...(p as ButtonProp)}>{children}</button>;
        }
      }
    },
    [elType]
  );

  return (
    <RootEl
      className={classnames(
        classes.container,
        classes[`type-${styleType}`],
        classes[`el-${elType}`],
        className
      )}
      {...rest}
    >
      <div className={classes.mask}></div>
      <div className={classnames(classes.content, contentClassName)}>
        {children}
      </div>
    </RootEl>
  );
};

export default GeneralButton;
