import React from 'react';

import styles from './CountdownTimer.module.scss';

import { useTimer } from 'hooks/useTimer';

interface Prop {
  className?: string;
  whiteColor?: boolean;
  textUpper?: boolean;
  countdownSeconds: number;
  onEnd?: () => void;
}

export default React.memo(function CountdownTimer({
  className,
  whiteColor,
  textUpper,
  countdownSeconds,
  onEnd,
}: Prop) {
  const { hours, minutes, seconds } = useTimer(countdownSeconds, {
    endFn: onEnd,
  });

  return (
    <div
      className={`${styles['countdown-timer']} ${className}`}
      data-text-upper={textUpper}
      data-white-color={whiteColor}
    >
      <div className={styles['countdown-timer-item']}>
        <span className={styles['countdown-time']}>{hours}</span>
        <span className={styles['countdown-hint']}>Hour</span>
      </div>
      <div className={styles['countdown-timer-delimiter-item']}>:</div>
      <div className={styles['countdown-timer-item']}>
        <span className={styles['countdown-time']}>{minutes}</span>
        <span className={styles['countdown-hint']}>Min</span>
      </div>
      <div className={styles['countdown-timer-delimiter-item']}>:</div>
      <div className={styles['countdown-timer-item']}>
        <span className={styles['countdown-time']}>{seconds}</span>
        <span className={styles['countdown-hint']}>Sec</span>
      </div>
    </div>
  );
});
