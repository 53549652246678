import React from 'react';
import classes from './LotteryNumber.module.scss';

type LotteryNumberProps = {
  value?: string;
};

export default function LotteryNumber(props: LotteryNumberProps) {
  const { value = 10 } = props;
  return (
    <strong className={classes.container}>
      <div className={classes.mask}></div>
      <div className={classes.content}>{value}</div>
    </strong>
  );
}
