import React, { useCallback } from 'react';

export function useKeyPressEnter() {
  const handleKeyPressEnter = useCallback(
    (fn: Function) => (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        fn();
      }
    },
    []
  );

  return { handleKeyPressEnter };
}
