import { useState, useEffect, useMemo } from 'react';

interface UseTimer {
  hours: string;
  minutes: string;
  seconds: string;
}

interface Option {
  endFn?: () => any;
}

const END_TIME = 0;
const END_TIME_TEXT = '00';

export function useTimer(
  countdownSeconds: number,
  { endFn }: Option
): UseTimer {
  const [isStartCountdown, setIsStartCountdown] = useState(false);
  const [currentSeconds, setCurrentSeconds] = useState<number>(0);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    let currentCountSeconds = countdownSeconds;
    setCurrentSeconds(currentCountSeconds);

    const countdownFn = () =>
      setTimeout(() => {
        if (currentCountSeconds > 0) {
          setIsStartCountdown(true);
          timeoutId = countdownFn();

          currentCountSeconds -= 1;
          setCurrentSeconds(currentCountSeconds);
          return;
        }

        setIsStartCountdown(false);
      }, 1000);

    timeoutId = countdownFn();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [countdownSeconds]);

  useEffect(() => {
    if (isStartCountdown && currentSeconds === END_TIME && endFn) {
      endFn();
    }
  }, [isStartCountdown, currentSeconds, endFn]);

  const hours = useMemo(() => {
    if (!currentSeconds) {
      return END_TIME_TEXT;
    }

    const hourNumber = Math.floor(currentSeconds / 3600);
    return hourNumber > 0 ? `0${hourNumber}`.slice(-2) : END_TIME_TEXT;
  }, [currentSeconds]);

  const minutes = useMemo(() => {
    if (!currentSeconds) {
      return END_TIME_TEXT;
    }

    const minutesNumber = Math.floor((currentSeconds % 3600) / 60);
    return minutesNumber > 0 ? `0${minutesNumber}`.slice(-2) : END_TIME_TEXT;
  }, [currentSeconds]);

  const seconds = useMemo(() => {
    if (!currentSeconds) {
      return END_TIME_TEXT;
    }

    const secondNumber = currentSeconds % 60;
    return secondNumber > 0 ? `0${secondNumber}`.slice(-2) : END_TIME_TEXT;
  }, [currentSeconds]);

  return {
    hours,
    minutes,
    seconds,
  };
}
