import React from 'react';
import classes from './LotteryListPagination.module.scss';
import { useLotteryHistoryPagination } from 'hooks/useLottery';
import LotteryListPaginationNumber from './components/LotteryListPaginationNumber';
import LotteryListPaginationArrow from './components/LotteryListPaginationArrow';
import classnames from 'classnames';

type LotteryListPaginationProps = {
  value?: string;
};

export default function LotteryListPagination(
  props: LotteryListPaginationProps
) {
  const { currentPageNumbers } = useLotteryHistoryPagination();
  return (
    <section className={classes.container}>
      <div className={classnames(classes.left, classes.arrow)}>
        <LotteryListPaginationArrow direction="left"></LotteryListPaginationArrow>
      </div>
      <div className={classes.numbers}>
        {currentPageNumbers
          .filter((n) => n.isDisplay)
          .map((n, i) => (
            <LotteryListPaginationNumber
              isDot={n.isDot}
              key={`LotteryListPaginationNumber-${n.index}`}
              pageNumber={n.pageNumber}
            ></LotteryListPaginationNumber>
          ))}
      </div>
      <div className={classnames(classes.right, classes.arrow)}>
        <LotteryListPaginationArrow direction="right"></LotteryListPaginationArrow>
      </div>
    </section>
  );
}
