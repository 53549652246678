import React, { useMemo, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

import styles from './DefaultHeader.module.scss';

import useLogoImage from 'hooks/useLogoImage';
import useLatestLotteryResultAndTime from 'hooks/useLatestLotteryResultAndTime';
import { RouteKey } from 'constants/route-path';
import { getRouteByRouteKey, getRoutesByRouteKeys } from 'libs/route';

import NavMenu from 'layouts/NavMenu/Index';
import CountdownTimer from 'components/CountdownTimer/CountdownTimer';
export interface Prop {
  showDetail?: boolean;
}
const navMenuKeys = [RouteKey.HOME, RouteKey.RULE, RouteKey.LOTTERY_HISTORY];

export default function DefaultHeader({ showDetail }: Prop) {
  const { current: lotteryHistoryLink } = useRef(
    getRouteByRouteKey(RouteKey.LOTTERY_HISTORY)
  );
  const { current: navMenu } = useRef(getRoutesByRouteKeys(navMenuKeys));
  const { LogoImage } = useLogoImage();
  const {
    latestLottery,
    isWaitingLottery,
    countdownSeconds,
    openCodeList,
    handleCountdownTimerEnd,
  } = useLatestLotteryResultAndTime();

  const lotteryNumberContent = useMemo(() => {
    if (isWaitingLottery) {
      return 'Waiting for the lottery!';
    }

    return openCodeList.map((o) => (
      <div key={o} className={styles['default-header-lottery-number-item']}>
        {o}
      </div>
    ));
  }, [isWaitingLottery, openCodeList]);

  const DetailNextLottery = useCallback(() => {
    if (!lotteryHistoryLink) {
      return (
        <div className={styles['default-header-next-lottery']}>
          <div className={styles['default-header-next-lottery-title']}>
            Countdown to Next Lottery
          </div>

          <CountdownTimer
            className={styles['default-header-next-lottery-countdown-timer']}
            countdownSeconds={countdownSeconds}
            onEnd={handleCountdownTimerEnd}
          />
        </div>
      );
    }
    return (
      <Link
        to={{
          pathname: lotteryHistoryLink.path,
          state: lotteryHistoryLink.defaultState,
          search: lotteryHistoryLink.defaultSearch || '',
        }}
        className={styles['default-header-next-lottery']}
      >
        <div className={styles['default-header-next-lottery-title']}>
          Countdown to Next Lottery
        </div>

        <CountdownTimer
          className={styles['default-header-next-lottery-countdown-timer']}
          countdownSeconds={countdownSeconds}
          onEnd={handleCountdownTimerEnd}
        />
      </Link>
    );
  }, [countdownSeconds, handleCountdownTimerEnd, lotteryHistoryLink]);

  if (!showDetail) {
    return (
      <header className={styles['default-header-style']}>
        <div className={styles['default-header-container']}>
          <div className={styles['default-header-brand']}>
            <LogoImage className={styles['default-header-logo']} />
            <NavMenu navMenu={navMenu} />
          </div>
        </div>
      </header>
    );
  }

  return (
    <header className={styles['default-header-style']}>
      <div className={styles['default-header-container']}>
        <div className={styles['default-header-brand']}>
          <LogoImage className={styles['default-header-logo']} />
          <NavMenu navMenu={navMenu} />
        </div>
        <section className={styles['default-header-detail']}>
          <h2 className={styles['default-header-title']}>CANADA LOTTERY</h2>
          <div className={styles['default-header-winner-number']}>
            Winning Numbers
            <span
              data-number-placeholder="00000000000"
              className={styles['default-header-winner-number-highlight']}
            >
              {!isWaitingLottery &&
                latestLottery &&
                latestLottery.uniqueIssueNumber}
            </span>
          </div>
          <div className={styles['default-header-lottery-number']}>
            {lotteryNumberContent}
          </div>
          <DetailNextLottery />
        </section>
      </div>
    </header>
  );
}
