import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { RouteUnit } from 'routes';

import styles from './NavMenu.module.scss';

interface Prop {
  className?: string;
  sameTheme?: boolean;
  navMenu: RouteUnit[];
}

const PREFIX_CLASS_NAME = 'nav-menu';
const PREFIX_SAME_CLASS_NAME = 'nav-menu-same';

export default React.memo(function NavMenu({
  className,
  sameTheme,
  navMenu,
}: Prop) {
  const currentPrefixClassName = useMemo(
    () => (sameTheme ? PREFIX_SAME_CLASS_NAME : PREFIX_CLASS_NAME),
    [sameTheme]
  );

  return (
    <nav className={className}>
      <ul className={styles[`${currentPrefixClassName}`]}>
        {navMenu.map((o) => (
          <li key={o.key} className={styles[`${currentPrefixClassName}-item`]}>
            <NavLink
              data-text={o.name}
              exact={o.exact}
              to={{
                pathname: o.path,
                state: o.defaultState,
                search: o.defaultSearch || '',
              }}
              className={styles[`${currentPrefixClassName}-item-link`]}
              activeClassName={
                styles[`${currentPrefixClassName}-item-link-active`]
              }
            >
              {o.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
});
