import React, { useMemo } from 'react';

import useLatestLotteryResultAndTime from 'hooks/useLatestLotteryResultAndTime';

import CountdownTimer from 'components/CountdownTimer/CountdownTimer';

import styles from './LotteryHistoryHeader.module.scss';

export default function LotteryHistoryHeader() {
  const {
    latestLottery,
    isWaitingLottery,
    countdownSeconds,
    openCodeList,
    handleCountdownTimerEnd,
  } = useLatestLotteryResultAndTime();

  const lotteryNumberContent = useMemo(() => {
    if (isWaitingLottery) {
      return 'Waiting for the lottery!';
    }

    return openCodeList.map((o) => (
      <div key={o} className={styles['lottery-history-lottery-number-item']}>
        {o}
      </div>
    ));
  }, [isWaitingLottery, openCodeList]);

  return (
    <section className={styles['lottery-history-header']}>
      <div className={styles['lottery-history-header-left']}>
        <div className={styles['lottery-history-winner-number']}>
          Winning Numbers
          <span
            data-number-placeholder="00000000000"
            className={styles['lottery-history-winner-number-highlight']}
          >
            {!isWaitingLottery &&
              latestLottery &&
              latestLottery.uniqueIssueNumber}
          </span>
        </div>
        <div className={styles['lottery-history-lottery-number']}>
          {lotteryNumberContent}
        </div>
      </div>
      <div className={styles['lottery-history-header-right']}>
        <div className={styles['lottery-history-next-lottery']}>
          <div className={styles['lottery-history-next-lottery-title']}>
            COUNTDOWN TO NEXT LOTTERY
          </div>
        </div>
        <CountdownTimer
          textUpper
          whiteColor
          className={styles['lottery-history-next-lottery-countdown-timer']}
          countdownSeconds={countdownSeconds}
          onEnd={handleCountdownTimerEnd}
        />
      </div>
    </section>
  );
}
