import React, { useCallback, Fragment } from 'react';
import classes from './RuleParagraph.module.scss';

type RuleParagraphProps = {
  title: string;
  titleStyle?: React.CSSProperties;
  content: string[][] | string;
  contentType: string | 'text' | 'table' | undefined;
  contentStyle?: React.CSSProperties;
};

export default function RuleParagraph(props: RuleParagraphProps) {
  const {
    title,
    content,
    contentType,
    contentStyle = {},
    titleStyle = {},
  } = props;

  const Content = useCallback(() => {
    switch (contentType) {
      case 'table': {
        const table: string[][] = content as string[][];
        const [trTitle, ...restTrs] = table;
        return (
          <table className={classes.contentTable}>
            <thead>
              <tr>
                {trTitle.map((t, i) => (
                  <th key={`table-tr-${i}`}>{t}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {restTrs.map((tl, i) => (
                <tr key={`table-tr-${i}`}>
                  {tl.map((t, ii) => (
                    <td key={`table-tr-${i}-td-${ii}`}>{t}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      case 'text':
      case undefined:
      default: {
        const text: string = content as string;
        return (
          <Fragment>
            {text.split('\n').map((c, index) => (
              <p key={`p=${index}`}>{c.length ? c : <br></br>}</p>
            ))}
          </Fragment>
        );
      }
    }
  }, [content, contentType]);

  return (
    <section className={classes.container}>
      <b style={titleStyle} className={classes.title}>
        {title}
      </b>
      <div className={classes.content} style={contentStyle}>
        <Content></Content>
      </div>
    </section>
  );
}
