import React from 'react';

import DefaultHeader from './DefaultHeader/Index';
import DefaultFooter from './DefaultFooter/Index';

export interface Prop extends React.Props<any> {
  showHeaderDetail?: boolean;
}

function DefaultLayout(props: Prop) {
  const { children, showHeaderDetail } = props;

  return (
    <>
      <DefaultHeader showDetail={showHeaderDetail} />
      {children}
      <DefaultFooter />
    </>
  );
}

export default DefaultLayout;
