import React, { useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

import LogoImage from 'assets/images/logo.png';

import { RouteKey } from 'constants/route-path';
import { getRouteByRouteKey } from 'libs/route';

export default function useLogoImage() {
  const { current: logoLink } = useRef(getRouteByRouteKey(RouteKey.HOME));

  const LogoImageComponent = useCallback(
    (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
      if (!logoLink) {
        return <img alt="logo" src={LogoImage} {...props} />;
      }

      return (
        <Link
          to={{
            pathname: logoLink.path,
          }}
        >
          <img alt="logo" src={LogoImage} {...props} />
        </Link>
      );
    },
    [logoLink]
  );

  return { LogoImage: LogoImageComponent };
}
