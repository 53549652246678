import React, { useMemo } from 'react';
import classnames from 'classnames';
import classes from './LotteryListPaginationArrow.module.scss';
import { Link } from 'react-router-dom';
import useSearchParams from 'hooks/useSearchParams';
import { useLotteryHistoryPagination } from 'hooks/useLottery';

type ArrowIconDirection = 'left' | 'up' | 'down' | 'right';

type LotteryListPaginationArrowProps = {
  direction?: ArrowIconDirection;
};

function ArrowSvg(props: { direction: ArrowIconDirection }) {
  const { direction = 'left' } = props || {};
  return (
    <svg
      className={classnames(classes.svg, classes[`dir-${direction}`])}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 10 17"
      xmlSpace="preserve"
    >
      <g id="UI-Design">
        <g
          id="Lottery-History"
          transform="translate(-594.000000, -2392.000000)"
        >
          <g id="search-number" transform="translate(594.000000, 2385.000000)">
            <path
              id="right-arrow"
              className="st0"
              d="M0.4,14.3l6.9-6.9C7.8,7,8.4,6.9,9,7.1s0.9,0.8,0.9,1.4v13.8c0,0.6-0.4,1.2-0.9,1.4     s-1.2,0.1-1.6-0.3l-6.9-6.9C-0.1,15.9-0.1,14.9,0.4,14.3z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function LotteryListPaginationArrow(
  props: LotteryListPaginationArrowProps
) {
  const { direction = 'left' } = props;
  const params = useSearchParams();
  const { lotteryPagination } = useLotteryHistoryPagination();
  const nextPageNumber = useMemo(() => {
    switch (direction) {
      case 'right': {
        return parseInt(params.page) + 1;
      }
      case 'left': {
        return parseInt(params.page) - 1;
      }
    }
  }, [direction, params.page]);
  const isDisabled = useMemo(() => {
    switch (direction) {
      case 'right': {
        return parseInt(params.page) >= lotteryPagination.pageTotalPageAmount;
      }
      case 'left': {
        return parseInt(params.page) <= 1;
      }
    }
  }, [direction, lotteryPagination.pageTotalPageAmount, params.page]);
  return (
    <Link
      to={{
        pathname: `/lottery-history`,
        search: `?page=${nextPageNumber}`,
        state: { page: nextPageNumber },
      }}
      className={
        isDisabled
          ? classnames(classes.container, classes.displayNone)
          : classes.container
      }
    >
      <ArrowSvg direction={direction}></ArrowSvg>
    </Link>
  );
}
