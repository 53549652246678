import { RouteKey } from 'constants/route-path';

import routes, { RouteUnit } from 'routes';

export function getRouteByRouteKey(key: RouteKey) {
  return routes.App.find((o) => o.key === key);
}

export function getRoutesByRouteKeys(keys: RouteKey[]) {
  return keys.map(getRouteByRouteKey).filter((o) => o) as RouteUnit[];
}
