import { LotteryState } from './stateTypes';
import { LotteryActionType } from 'store/actionTypes/lotteryActionTypes';
import ActionType from 'store/actionTypes/actionTypeEnum';
import { LotteryHistory } from 'types/apis/lottery.type';

const initialState: LotteryState = {
  latestLottery: null,
  lotteryList: [],
  lotteryHistoryList: [],
  lotteryHistoryListPaged: [[]],
  lotteryPagination: {
    pageNow: 0,
    pageDataAmount: 10,
    pageTotalPageAmount: 0,
  },
};

export function lotteryReducer(
  state = initialState,
  action: LotteryActionType
): LotteryState {
  switch (action.type) {
    case ActionType.SET_LOTTERY_LIST: {
      return {
        ...state,
        lotteryList: action.payload,
      };
    }
    case ActionType.SET_LATEST_LOTTERY: {
      return {
        ...state,
        latestLottery: action.payload,
      };
    }
    case ActionType.SET_LOTTERY_HISTORY_LIST: {
      const lotteryHistory = action.payload;
      if (lotteryHistory.length === 0) {
        return {
          ...state,
          lotteryHistoryListPaged: [[]],
          lotteryHistoryList: action.payload,
          lotteryPagination: {
            ...state.lotteryPagination,
            pageTotalPageAmount: 0,
          },
        };
      }

      const lotteryHistoryListPaged = lotteryHistory.reduce<LotteryHistory[][]>(
        (pre, cur, index) => {
          const I = index % state.lotteryPagination.pageDataAmount;
          if (I === 0) {
            return [...pre, [cur]];
          }
          const last = pre.pop();
          return [...pre, [...(last || []), cur]];
        },
        []
      );
      return {
        ...state,
        lotteryHistoryList: action.payload,
        lotteryHistoryListPaged,
        lotteryPagination: {
          ...state.lotteryPagination,
          pageTotalPageAmount: lotteryHistoryListPaged.length,
        },
      };
    }

    case ActionType.SET_LOTTERY_PAGINATION: {
      return {
        ...state,
        lotteryPagination: {
          ...state.lotteryPagination,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}
