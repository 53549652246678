import ActionType from 'store/actionTypes/actionTypeEnum';
import { ErrorKey } from 'store/reducers/stateTypes';
import { ErrorActionType } from 'store/actionTypes/errorActionTypes';

// TypeScript infers that this function is returning SendMessageAction
export function setError(key: ErrorKey, error: Error): ErrorActionType {
  return {
    type: ActionType.SET_ERROR_STATE,
    payload: {
      key,
      error,
    },
  };
}

export function removeError(key: ErrorKey): ErrorActionType {
  return {
    type: ActionType.REMOVE_ERROR_STATE,
    payload: {
      key,
    },
  };
}

export function clearError(): ErrorActionType {
  return {
    type: ActionType.CLEAR_ERROR_STATE,
  };
}
