import { useEffect, useState, useCallback, useMemo } from 'react';

import { Lottery } from 'types/apis/lottery.type';

import api from 'services/api';
import useLottery from 'hooks/useLottery';

interface UseLatestLotteryResultAndTime {
  latestLottery: Lottery | null;
  isWaitingLottery: boolean;
  countdownSeconds: number;
  openCodeList: string[];
  handleCountdownTimerEnd: () => Promise<void>;
}

export default function useLatestLotteryResultAndTime(): UseLatestLotteryResultAndTime {
  const { latestLottery, queryAndSetLotteryList } = useLottery();
  const [countdownSeconds, setCountdownSeconds] = useState(0);
  const [isWaitingLottery, setIsWaitingLottery] = useState(false);

  useEffect(() => {
    const getCountdownSeconds = async () => {
      if (latestLottery) {
        const { data: currentTime } = await api.getCurrentTime();

        const { nextOfficialOpenTimeEpoch } = latestLottery;
        setCountdownSeconds(nextOfficialOpenTimeEpoch - currentTime);
      }
    };

    getCountdownSeconds();
  }, [latestLottery]);

  const openCodeList = useMemo(() => {
    if (latestLottery) {
      const { openCode } = latestLottery;
      return openCode.split(',');
    }
    return [];
  }, [latestLottery]);

  const handleCountdownTimerEnd = useCallback(async () => {
    setIsWaitingLottery(true);

    const [currentTimeResult, latestLotteryListResult] = await Promise.all([
      api.getCurrentTime(),
      queryAndSetLotteryList(),
    ]);
    const { data: currentTimeData } = currentTimeResult;
    const [latestLotteryData] = latestLotteryListResult;

    const { nextOfficialOpenTimeEpoch } = latestLotteryData;
    const isLatestResult = nextOfficialOpenTimeEpoch > currentTimeData;

    if (!isLatestResult) {
      setTimeout(() => {
        handleCountdownTimerEnd();
      }, 5000);
      return;
    }

    setIsWaitingLottery(false);
  }, [queryAndSetLotteryList]);

  return {
    latestLottery,
    isWaitingLottery,
    countdownSeconds,
    openCodeList,
    handleCountdownTimerEnd,
  };
}
