import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from 'routes';
import './App.scss';

function App() {
  return (
    <Switch>
      {routes.App.map((rt) => (
        <Route exact={rt.exact} key={rt.key} path={rt.path}>
          {rt.component}
        </Route>
      ))}
    </Switch>
  );
}

export default App;
