import React from 'react';

import DefaultLayout, { Prop as LayoutProp } from 'layouts/DefaultLayout';

export function generatorPageComponent(
  PageComponent: React.ComponentType,
  layoutProps?: LayoutProp
) {
  return function LayoutPageComponent() {
    return (
      <DefaultLayout {...layoutProps}>
        <PageComponent />
      </DefaultLayout>
    );
  };
}
