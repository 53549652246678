import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import { useCallback, useMemo } from 'react';
import api from 'services/api';
import { Lottery, LotteryHistory } from 'types/apis/lottery.type';
import {
  setLotteryList,
  setLatestLotteryList,
  setLotteryHistoryList,
  setLotteryPagination,
} from 'store/actions/lotteryActions';
import useSearchParams from './useSearchParams';
import { setError, removeError } from 'store/actions/errorActions';
import { useHistory } from 'react-router-dom';

const selection = (state: RootState) => state.lottery;

const textMap: Record<string, string> = {
  id: 'number',
  date: 'Date',
};

export default function useLottery() {
  const {
    latestLottery,
    lotteryList,
    lotteryHistoryList,
    lotteryHistoryListPaged,
  } = useSelector(selection);
  const dispatch = useDispatch();
  const hist = useHistory();
  const queryAndSetLotteryList = useCallback(
    async (limit?) => {
      const { data: list }: { data: Lottery[] } = await api.getLotteryList({
        limit,
      });
      dispatch(setLotteryList(list));
      dispatch(setLatestLotteryList(list[0]));
      return list;
    },
    [dispatch]
  );

  const queryAndSetLotteryHistoryList = useCallback(
    async (by?: 'id' | 'date', params?: { date?: number; id?: string }) => {
      try {
        dispatch(removeError('lotteryHistory'));
        const { [by || 'date']: par } = params || {};
        const list = await (() => {
          switch (by) {
            case 'id': {
              return api
                .getLotteryListById({ uniqueIssueNumber: par as string })
                .then((res): LotteryHistory[] => {
                  const { data: lotteryNumbers } = res;
                  if (lotteryNumbers.length) {
                    return [
                      {
                        uniqueIssueNumber: parseInt(par as string, 10),
                        openCode: lotteryNumbers
                          .split(',')
                          .map((n) => n.trim())
                          .join(),
                      },
                    ];
                  } else {
                    return [];
                  }
                });
            }
            case 'date':
            default: {
              return api
                .getLotteryListByDate({ dateMillis: par as number })
                .then(({ data }) => data);
            }
          }
        })();
        hist.replace(`${hist.location.pathname}?page=1`);
        if (list.length) {
          dispatch(setLotteryHistoryList(list));
        } else {
          throw Error(
            `There is no any result for this ${textMap[by || 'date']} : ${par}`
          );
        }
      } catch (error) {
        dispatch(setLotteryHistoryList([]));
        dispatch(setError('lotteryHistory', error));
      }
    },
    [dispatch, hist]
  );

  return {
    latestLottery,
    lotteryList,
    lotteryHistoryList,
    queryAndSetLotteryList,
    queryAndSetLotteryHistoryList,
    lotteryHistoryListPaged,
  };
}

const CONTAINER_LENGTH = 7;
const CONTAINER_R = Math.floor(CONTAINER_LENGTH / 2);

const getEage = (
  pageTotalPageAmount: number
): { left: number; right: number } => {
  return { left: CONTAINER_R + 1, right: pageTotalPageAmount - CONTAINER_R };
};

type NumberObj = {
  index: number;
  pageNumber: number;
  isDot: boolean;
  isDisplay: boolean;
};

export function useLotteryHistoryPagination() {
  const { lotteryPagination } = useSelector(selection);
  const params = useSearchParams();
  const currentPageNumber = parseInt(params.page || '1');
  const dispatch = useDispatch();

  const nextPage = useCallback(() => {
    dispatch(
      setLotteryPagination({
        key: 'pageNow',
        value: currentPageNumber + 1,
      })
    );
  }, [currentPageNumber, dispatch]);

  const prevPage = useCallback(() => {
    dispatch(
      setLotteryPagination({
        key: 'pageNow',
        value: currentPageNumber - 1,
      })
    );
  }, [currentPageNumber, dispatch]);

  const currentEage = useMemo(
    () => getEage(lotteryPagination.pageTotalPageAmount),
    [lotteryPagination.pageTotalPageAmount]
  );

  const currentPageNumbersV2 = useMemo<NumberObj[]>(() => {
    const tempArray = Array(lotteryPagination.pageTotalPageAmount)
      .fill(0)
      .map((_, index: number) => ({
        index,
        pageNumber: index + 1,
        isDot: false,
        isDisplay: false,
      }))
      .map((n, _, array) => {
        const temp = (() => {
          if (
            [1, lotteryPagination.pageTotalPageAmount].includes(n.pageNumber)
          ) {
            return {
              ...n,
              isDisplay: true,
            };
          }
          if (currentPageNumber <= currentEage.left) {
            return {
              ...n,
              isDisplay: n.pageNumber - 1 < CONTAINER_LENGTH,
            };
          }
          if (currentPageNumber >= currentEage.right) {
            return {
              ...n,
              isDisplay:
                lotteryPagination.pageTotalPageAmount - n.pageNumber <
                CONTAINER_LENGTH,
            };
          }
          return {
            ...n,
            isDisplay: Math.abs(n.pageNumber - currentPageNumber) < CONTAINER_R,
          };
        })();

        if (
          [2, lotteryPagination.pageTotalPageAmount - 1].includes(n.pageNumber)
        ) {
          const result: NumberObj = {
            ...temp,
            isDisplay: true,
            isDot: !temp.isDisplay,
          };
          return result;
        }

        const result: NumberObj = {
          ...temp,
        };
        return result;
      });

    return tempArray;
  }, [
    currentEage.left,
    currentEage.right,
    currentPageNumber,
    lotteryPagination.pageTotalPageAmount,
  ]);

  return {
    nextPage,
    prevPage,
    lotteryPagination,
    currentPageNumber,
    currentPageNumbers: currentPageNumbersV2,
  };
}
